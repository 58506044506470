.modalStyle{
    position: absolute;
    overflow-y: scroll;
    height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    background-color: #f0f0f0; 
    border: 0 solid #000;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1); 
    padding: 16px; 
}

.childModalStyle{
    position: absolute;
    overflow-y: scroll;
    height: 70vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    background-color: #f0f0f0; /* Replace with your desired background color */
    border: 0 solid #000;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1); /* Replace with your desired box shadow */
    padding: 16px; /* Adjust the padding as needed */
}


.form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
}

.selector_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}


@media screen and (max-width:650px){
    .modalStyle{
        width:27rem;
    }
    .childModalStyle{
        width: 25rem;
    }
}

@media screen and (max-width:480px){
    .modalStyle{
        width:20rem;
    }
    .childModalStyle{
        width: 18rem;
    }
}