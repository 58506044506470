.modalStyle{
  position: absolute;
  overflow-y: scroll;
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  background-color: #f0f0f0; /* Replace with your desired background color */
  border: 0 solid #000;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1); /* Replace with your desired box shadow */
  padding: 16px; /* Adjust the padding as needed */
}

.childModalStyle{
    position: absolute;
    overflow-y: scroll;
    height: 70vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    background-color: #f0f0f0; /* Replace with your desired background color */
    border: 0 solid #000;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1); /* Replace with your desired box shadow */
    padding: 16px; /* Adjust the padding as needed */
}

.modal_heading{
    color: #333;
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    padding: 10px;
    font-weight: 500;
    width: 100%;
}

.modal_stepper{
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: row;
    gap:5px;
    flex-wrap: wrap;
}

.stepper{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    opacity: 0.4;
    cursor: pointer;
}

.stepper.active{
    opacity: 1;
}

.stepper.fulfilled{
    opacity: 1;
}

.step{
    width: 30px;
    height: 30px;
    background: #232323;
    box-shadow: 0 0 0 3px #383838;
    background: #696CFF;
    box-shadow: 0 0 0 2px #4f51dc7d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step.fulfilled{
    position: relative;
    background: rgb(62, 218, 62);
    box-shadow: 0 0 0 2px #4fdc6e7d;
}

.step.fulfilled .step_no{
    display: none;
}

.step.fulfilled::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
}
.step_no{
    font-size: 14px;
    color: #f0f0f0;
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
}

.step_info{
    font-size: 12px;
    color: #333;
    font-family: 'Montserrat',sans-serif;
    text-align: center;
}

.line_container{
    width: 50px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
}

.line_container.active{
    opacity: 1;
}

.line_container.fulfilled{
    opacity: 1;
}

.line{
    width: 100%;
    height: 1px;
    background: #696CFF;
}

.line_container.fulfilled .line{
    background: rgb(62, 218, 62);
}



.btns_wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}
.prev_btn,.next_btn{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #232323;
    border: none;
    outline: none;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #383838;
}

.form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
}

.add_btn{
    border: none;
    outline: none;
    /* background-color: rgb(0, 216, 0); */
    background-color: #696CFF;
    padding: 0.4rem;
    font-size:1rem;
    color: white;
    border-radius: 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.selector_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.table_container {
    width: 100%;
}

.table_sub_container {
    width: 100%;
        max-height: 500px;
        overflow-y: scroll;
        overflow-x:auto;
        margin-bottom: 50px;
        width: 100%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    color: #fff;
    /* background-color: #212135; */
    background-color: #fff;
  }
  
  .th {
    /* background-color: #000000; */
    background-color: #F8F9FA;
    border: 1px solid #555;
    padding: 10px;
    text-align: left;
    color: #343A40;
  }

  .td {
    border: 1px solid #555;
    padding: 10px;
    color: #343A40;
  }

  .fullJSON{
    text-align: center;
    width: 100%;
  }

@media screen and (max-width:650px){
    .modalStyle{
        width:27rem;
    }
    .childModalStyle{
        width: 25rem;
    }
}

@media screen and (max-width:480px){
    .modalStyle{
        width:20rem;
    }
    .childModalStyle{
        width: 18rem;
    }
}