.main {
    display: grid;
    grid-template-columns: 15rem auto;
    gap: 1rem;
    transition: all 0.3s;
}

.sidebar {
    position: fixed;
    min-height: 100dvh;
    max-width: 15rem;
    width: 100%; 
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    z-index: 99;
    padding: 1rem 0 2rem 0;
    transition: all 0.3s;
}

.sidebar::-webkit-scrollbar {
    width: 3px; 
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: #E5E5E5;
    border-radius: 4px;
}

.sidebar_header{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.sidebar_logo{
    width: 50px;
    height: auto;
    object-fit: contain;
}

.sidebar_icon{
    position: absolute;
    right: -0.5rem;
    top: 1rem;
    transform: translate(0,0);
}
.sidebar_icon .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #696CFF;
    cursor: pointer;
    /* box-shadow: 0 2rem 2rem rgba(0,0,0,0.6); */
}

.arrow_icon{
    color: white;
}


.dashboard_text{
    /* color: white; */
    color: #343A40;
    font-size: 1.5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
}

.container {
    grid-area:1/2/2/-1;
    margin-right: 1rem;
    margin-bottom: 1rem;
    min-height: 100vh;
    background-attachment: fixed;
    overflow-x: hidden;
    transition: all 0.3s;
}

.sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem 2rem 1rem;
    z-index: 99;
    overflow: hidden;
    border-radius:0.3rem;
    margin-top: 1rem;
    /* background-color:#212135; */
    background-color:#fff;
}

.form{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.search_btn{
    border: none;
    outline: none;
    background-color: rgb(0, 216, 0);
    padding: 0.4rem;
    font-size:1rem;
    color: white;
    border-radius: 0.2rem;
    cursor: pointer;
}

.title_container{
    width: 100%;
    border: 1px solid #3333337c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
}

.title_sub_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
}

.title_sub_container h3{
    font-size: 1rem;
    font-family: 'Montserrat',sans-serif;
    color: #333;
}
.title_sub_container i{
    font-size: 1rem;
    color: #333;
}

.table_container {
    width: 100%;
    overflow: hidden;
}

.table_sub_container {
    width: 100%;
    height: 500px;
}

.main.short{
    display: grid;
    grid-template-columns: 3rem auto;
    gap: 1rem;
    transition: all 0.3s;
}
.sidebar_header.short{
    display: none;
}
.sidebar.short {
    max-width: 3rem;
}

.upload{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #2727270b;
    /* padding: 2rem; */
}

/* .upload_animation{
    height: 35rem;
    width: 30rem;
    margin: -4rem;
    cursor: crosshair;
    overflow: hidden;

} */

.review{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 22rem;
    width: 100%;
    background-color: white;
    
}

.btn_another{
    border: none;
    outline: none;
    background-color: #000210;
    padding: 0.4rem 1rem;
    font-size:1rem;
    color: white;
    border-radius: 0.2rem;
    cursor: pointer;
    float: right;
    margin: 10px;
}

.upload_btn{
    border: none;
    outline: none;
    background-color: rgb(105 108 255);
    padding: 0.4rem 1rem;
    font-size:1.5rem;
    color: white;
    border-radius: 0.2rem;
    cursor: pointer;
    float: right;

}

.upload_btn_container{
    width: 100%;
    display: flex;
    justify-content: end;
}

.player{
    /* background-color: black; */
}

.progressBar{
    width: 100%;
    height: 10px;
    border-radius: none  !important;
    z-index: 99;
}

.dragdropContainer{
    padding: 2rem;
}

.btns{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.dropzone {
    border: 2px dashed #cccccc;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
}

.dropzone.active {
    border-color: #0000ff;
}

.dropzone p {
    margin-top: 10px;
    font-size: 16px;
}

.btn_container{
    padding: 1rem;
}




@media screen and (max-width:900px){
    .form{
            display: flex;
            justify-content:center;
            align-items: flex-start;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
    }
    .service{
        width: 100%;
    }
    .partner{
        width: 100%;
    }
    .client{
        width: 100%;
    }
    .start_date{
        width: 100%;
    }
    .end_date{
        width: 100%;
    }
    .main {
        grid-template-columns: 8rem auto;
    }
    .sidebar {
        max-width: 8rem;
    }
    .dashboard_text{
        color: #343A40;
        font-size: 1.3rem;
        font-family: 'Montserrat',sans-serif;
        font-weight: 400;
    }

    /* .sidebar_header{
        display: none;
    } */
}

@media screen and (max-width: 700px) {
    .main {
        grid-template-columns: 0rem auto;
        gap: 0rem;
    }
    .sidebar {
        display: none;
    }
    .sidebar_header {
        display: none;
    }
    .main.short{
        display: grid;
        grid-template-columns: 0rem auto;
        gap: 0rem;
        transition: all 0.3s;
    }
    .sidebar_header.short{
        display: none;
    }
    .sidebar.short {
        max-width: 0rem;
    }
    .container {
        width: 100dvw;
        min-height: 100vh;
        background-attachment: fixed;
        overflow-x: hidden;
        transition: all 0.3s;
    }


}



/* Search By Click Id Styles... */

.click_id_form{
    background-color: #E7E7E7;
    padding: 15px 10px 15px 10px;
    width: 100%;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 10px;
}

.modal_content{
    padding: 20px 0 20px 0;
}