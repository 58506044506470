
.container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    background-color: white;
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    
}

.top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close_btn{
    border: none;
    outline: none;
    /* background-color: rgb(0, 216, 0); */
    background-color: red;
    padding: 0.5rem;
    font-size:1rem;
    color: white;
    border-radius: 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;  
}

.header{
    font-size: 1.5rem;
    font-weight: 600;
}

.text{
    text-align: center;
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.player{
    width: 100%;
}

.animation{
    width: 40%;
}